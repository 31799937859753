import { useCallback, useEffect, useRef, useState } from "react";
import { Moment } from "../../Moment";
import { Arrow } from "../../LiveVideoModal/components/Arrow";

export const PcSlide = ({ soundValue, handleModalOn, slideItems }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const divRef = useRef(null);
  const momentRef = useRef([]);

  useEffect(() => {
    if (momentRef.current && slideItems.length) {
      momentRef.current[currentSlide - 2]?.classList.add("side-slide");
      momentRef.current[currentSlide - 1]?.classList.add("prev-slide");
      momentRef.current[currentSlide].classList.add("current-slide");
      momentRef.current[currentSlide + 1]?.classList.add("next-slide");
      momentRef.current[currentSlide + 2]?.classList.add("side-slide");
      momentRef.current[currentSlide - 1]?.querySelector("video")?.pause();
      momentRef.current[currentSlide + 1]?.querySelector("video")?.pause();
      momentRef.current[currentSlide].querySelector("video")?.play();
    }
  }, [currentSlide, slideItems]);
  const handleArrowClick = (direction) => {
    const sign = direction === "next" ? +1 : -1;
    divRef.current.style.transform = `translate(-${
      (currentSlide + sign) * 375
    }px, -50%)`;
    setCurrentSlide((prev) => (prev = prev + sign));
    momentRef.current[currentSlide - 2]?.classList.remove("side-slide");
    momentRef.current[currentSlide - 1]?.classList.remove("prev-slide");
    momentRef.current[currentSlide].classList.remove("current-slide");
    momentRef.current[currentSlide + 1]?.classList.remove("next-slide");
    momentRef.current[currentSlide + 2]?.classList.remove("side-slide");
  };
  const nextArrowClick = () => {
    if (currentSlide === momentRef.current.length - 1) {
      divRef.current.style.transform = `translate(-${
        0 * 375
      }px, -50%)`;
      setCurrentSlide(0);
      momentRef.current[currentSlide - 2]?.classList.remove("side-slide");
      momentRef.current[currentSlide - 1]?.classList.remove("prev-slide");
      momentRef.current[currentSlide].classList.remove("current-slide");
      momentRef.current[currentSlide + 1]?.classList.remove("next-slide");
      momentRef.current[currentSlide + 2]?.classList.remove("side-slide");
      momentRef.current[0].classList.add("current-slide");
      momentRef.current[0 + 1]?.classList.add("next-slide");
      momentRef.current[0 + 2]?.classList.add("side-slide");
      momentRef.current[currentSlide - 1]?.querySelector("video")?.pause();
      momentRef.current[currentSlide + 1]?.querySelector("video")?.pause();
      momentRef.current[0].querySelector("video")?.play();
      return;
    }
    handleArrowClick("next");
  };
  const prevArrowClick = () => {
    if (currentSlide === 0) return;
    handleArrowClick("prev");
  };

  return (
    <>
      <Arrow handleClick={nextArrowClick} />
      <div
        className={`w-auto flex justify-center items-center absolute left-[calc(50%-235px)] top-1/2 -translate-y-1/2 transition-all`}
        ref={divRef}
      >
        {slideItems.map((item, index) => (
          <div
            key={index}
            className={`slide ${
              index === 0 && currentSlide === index
                ? "mx-12"
                : currentSlide === index
                ? "mx-12"
                : ""
            }`}
            ref={(e) => (momentRef.current[index] = e)}
          >
            <Moment
              image={`assets/sample_video${index + 1}.mp4`}
              imageWidth={"w-[375px] h-[716px]"}
              autoPlay={currentSlide === index}
              nextArrowClick={nextArrowClick}
              handleModalOn={handleModalOn}
              muted={soundValue === 0 ? true : false}
              item={item}
              currentSlide={slideItems[currentSlide]}
            />
          </div>
        ))}
      </div>
      <Arrow direction={"prev"} handleClick={prevArrowClick} />
    </>
  );
};
