import GooglePlay from "../../assets/IconGoogleBlack.png";
import AppStore from "../../assets/IconAppleBlack.svg";
import { isMacOs } from "../../lib/checkDevices";
export const LeftSectionInfo = ({
  image = null,
  headerText = null,
  buttonGradientStyle = null,
  lineHeight = "",
  onClick = null,
  subHeaderText,
  discription,
  innerInfoDivRef: { innerInfoDivRef, index },
  marginTop,
}) => {
  const isFirstScreen = image ? true : false;
  return (
    <>
      <article className={`${isFirstScreen ? "" : "mt-20 sm:mt-28"}`}>
        <div className="pt-[100px] sm:pt-0">
          <div
            className="info relative text-left"
            ref={(e) => (innerInfoDivRef.current[index] = e)}
          >
            {isFirstScreen && (
              <img
                src={image}
                className="absolute -left-[25px] -top-[170px] w-[266px] sm:hidden"
              ></img>
            )}
            {headerText && (
              <h2
                dangerouslySetInnerHTML={{ __html: headerText }}
                className="text-[60px] mt-16 sm:mt-0 font-semibold sm:font-bold sm:text-xl"
                style={{
                  fontFamily: "Shadeerah",
                }}
              ></h2>
            )}
            <h3
              className={`text-left text-[50px] sm:font-bold sm:text-xl ${
                isFirstScreen
                  ? "sm:text-center mt-[180px] font-medium sm:mt-9"
                  : "sm:mt-2"
              } ${lineHeight}`}
              style={{
                fontFamily: "Pretendard",
              }}
              dangerouslySetInnerHTML={{ __html: subHeaderText }}
            ></h3>
            <p
              className={`text-left text-[32px] sm:text-sm ${
                isFirstScreen ? "sm:text-center" : ""
              } sm:leading-[210%] ${marginTop}`}
              dangerouslySetInnerHTML={{ __html: discription }}
            ></p>
            {buttonGradientStyle && (
              <div className="flex space-x-4 mt-11 sm:mt-10 sm:space-x-0 sm:justify-center">
                <a
                  href={"https://play.google.com/store/apps/details?id=com.xpace.org.rn&pcampaignid=web_share"}
                  target="_blank"
                  className={`flex justify-center items-center bg-white w-[226px] sm:w-[142px] h-[78px] sm:h-[49px] text-2xl sm:text-sm rounded-2xl text-[#3C3C3C] sm:font-semibold ${
                    isMacOs() ? "sm:hidden" : ""
                  }`}
                  style={buttonGradientStyle}
                  onClick={onClick}
                >
                  <img
                    className="mr-2 sm:w-5"
                    src={GooglePlay}
                    alt="플레이스토어 로고"
                  />
                  Google Play
                </a>
                <a
                  href={
                    "https://apps.apple.com/kr/app/xpace-%EC%97%91%EC%8A%A4%ED%8E%98%EC%9D%B4%EC%8A%A4-%EB%AA%A8%EC%9E%84-%ED%8C%8C%ED%8B%B0-%EC%86%8C%EC%85%9C-%EC%B7%A8%EB%AF%B8-%ED%96%89%EC%82%AC/id1658395440"
                  }
                  target="_blank"
                  className={`flex justify-center items-center bg-white w-[226px] sm:w-[142px] h-[78px] sm:h-[49px] text-2xl sm:text-sm rounded-2xl text-[#3C3C3C] sm:font-semibold ${
                    isMacOs() ? "" : "sm:hidden"
                  }`}
                  style={buttonGradientStyle}
                >
                  <img
                    className="mr-2 sm:w-5"
                    src={AppStore}
                    alt="앱스토어 로고"
                  />
                  App Store
                </a>
              </div>
            )}
          </div>
        </div>
      </article>
    </>
  );
};
