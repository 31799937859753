import { ImageBtn } from "./components/ImageBtn";
import { Profile } from "./components/Profile";
import Like from "../../assets/home_like.png";
import DisLike from "../../assets/home_dislike.png";
import Comment from "../../assets/home_message.png";
import Start from "../../assets/home_star.png";
import Share from "../../assets/home_share.png";
import More from "../../assets/home_more.png";
import PersonSearch from "../../assets/person_search.png";
import X from "../../assets/imgHomeX.png";
import Equal from "../../assets/iconHomeEqual.png";
import Space from "../../assets/imgHomeSpace.png";
import Search from "../../assets/find.png";
import { Chats } from "./components/Chats";
import { useEffect, useState } from "react";
import { isiOS } from "../../lib/checkDevices";

export const Moment = ({
  autoPlay,
  item = null,
  image = null,
  imageWidth = null,
  isMobile = false,
  currentSlide = null,
  muted = true,
  loop = false,
  nextArrowClick = null,
  handleModalOn = null,
}) => {
  const [ratio, setRatio] = useState(0);
  const [currentContent, setCurrentContent] = useState(0);
  const [ratioInterval, setratioInterval] = useState(0);
  const [createdAt, setCreatedAt] = useState("");
  const [endedContent, setEndedContent] = useState(false);

  useEffect(() => {
    let imageRatio = 0;
    let interval = 0;
    if (currentSlide && item) {
      clearInterval(ratioInterval);
      const contentCreatedAt = new Date(
        item?.contents[currentContent].createdAt
      );
      const now = new Date();
      const timeDiff = new Date(now.getTime() - contentCreatedAt.getTime());
      setCreatedAt(
        timeDiff > 1000 * 3600 * 24
          ? `${Math.ceil(timeDiff / (1000 * 3600 * 24))}일 전`
          : timeDiff.getHours() > 0
          ? `${timeDiff.getHours() + "시간"}`
          : `${
              timeDiff.getMinutes() == "0"
                ? "지금"
                : timeDiff.getMinutes() + "분"
            }`
      );
      if (endedContent && currentSlide.id === item.id) {
        setCurrentContent(0);
        setEndedContent(false);
      }
      if (
        !currentSlide.contents[currentContent]?.thumbnail &&
        currentSlide.id === item.id
      ) {
        interval = setInterval(() => {
          imageRatio += 50 / 5000;
          if (imageRatio >= 1) {
            if (currentSlide?.contents.length - 1 === currentContent) {
              setEndedContent(true);
              nextArrowClick && nextArrowClick();
            } else {
              setCurrentContent((prev) => ++prev);
            }
            clearInterval(interval);
          }
          setRatio(imageRatio);
        }, [100]);
        setratioInterval(interval);
      }
    }
  }, [currentContent, currentSlide]);

  useEffect(() => {
    return () => clearInterval(ratioInterval);
  }, [ratioInterval]);

  const handleButton = (e) => {
    e.preventDefault();
    if (currentSlide?.id !== item?.id) return;
    if (isMobile) {
      const linkElement = document.createElement("a");
      linkElement.setAttribute(
        "href",
        isiOS()
          ? "https://apps.apple.com/kr/app/xpace-%EC%97%91%EC%8A%A4%ED%8E%98%EC%9D%B4%EC%8A%A4-%EB%AA%A8%EC%9E%84-%ED%8C%8C%ED%8B%B0-%EC%86%8C%EC%85%9C-%EC%B7%A8%EB%AF%B8-%ED%96%89%EC%82%AC/id1658395440"
          : "https://play.google.com/store/apps/details?id=com.xpace.org.rn&pcampaignid=web_share"
      );
      linkElement.setAttribute("target", "_blank");
      linkElement.click();
      return;
    }
    handleModalOn();
  };

  return (
    <div
      className={`${imageWidth} bg-black relative rounded-xl overflow-hidden`}
    >
      <div
        className="absolute top-2.5 w-full px-2.5 sm:top-1 sm:px-1.5 z-10"
        style={{ fontFamily: "Pretendard" }}
      >
        <div className="flex justify-between w-full">
          <div>
            <div className="flex items-center">
              <img className="w-[18px] h-[18px] sm:w-3 sm:h-3" src={X}></img>
              <img className="w-2 h-2 mx-1 sm:w-1 sm:h-1" src={Equal}></img>
              <span className="font-semibold text-[15px] sm:text-[10px] text-white">
                {item?.xName}
              </span>
            </div>
            <div className="flex space-x-1.5 items-center mt-2.5 sm:mt-0">
              <img className="w-[64px] sm:w-10" src={Space}></img>
              <span className="text-sm sm:text-[9px] text-white">
                {item?.spaceName}
              </span>
            </div>
          </div>
          <div className="flex items-center">
            <button
              onClick={handleButton}
              className={`${
                currentSlide?.id !== item?.id ? "cursor-default" : ""
              }`}
            >
              <img
                src={Search}
                className="w-6 h-6 mr-5 sm:w-4 sm:h-4 sm:mr-2.5"
              ></img>
            </button>
            <button
              onClick={handleButton}
              className={`${
                currentSlide?.id !== item?.id ? "cursor-default" : ""
              }`}
            >
              <p className="text-[#3C3C3C] text-sm py-1 px-4 sm:text-[9px] sm:py-0 sm:px-2.5 bg-white rounded-full font-semibold">
                JOIN
              </p>
            </button>
          </div>
        </div>
        <div className="w-full h-1 mt-2.5 space-x-1 flex sm:mt-1.5 sm:h-0.5">
          {item?.contents.map((_, index) => (
            <div
              key={index}
              className="relative h-1"
              style={{ width: `calc(100% / ${item?.contents.length})` }}
            >
              <div className="absolute h-1 w-full sm:h-0.5 bg-[#EEEEEE] rounded-sm opacity-20"></div>
              <div
                className="absolute h-1 sm:h-0.5 bg-white rounded-sm z-10"
                style={{
                  width: `calc(${
                    currentContent === index
                      ? ratio
                      : currentContent > index
                      ? 1
                      : 0
                  } * 100%)`,
                }}
              ></div>
            </div>
          ))}
        </div>
      </div>
      {item?.contents[currentContent].file.includes(".mp4") ? (
        <video
          playsInline
          className={`${imageWidth} rounded-xl`}
          src={item?.contents[currentContent].file}
          alt={`스크린샷 비디오${1}`}
          autoPlay={autoPlay}
          muted={muted}
          loop={loop}
          onEnded={() => {
            if (item?.contents.length - 1 !== currentContent) {
              setCurrentContent((prev) => ++prev);
            } else {
              clearInterval(ratioInterval);
              setEndedContent(true);
              nextArrowClick && nextArrowClick();
            }
          }}
          onProgress={(e) => {
            if (currentSlide?.id === item?.id) {
              clearInterval(ratioInterval);
              setratioInterval(
                setInterval(() => {
                  setRatio(
                    e.target.currentTime /
                      (e.target.duration ? e.target.duration : 1)
                  );
                }, [100])
              );
            }
          }}
          style={{ WebkitTransformStyle: "preserve-3d" }}
        ></video>
      ) : (
        <img
          className={`${imageWidth} object-contain`}
          src={item?.contents[currentContent].file}
          alt={`스크린샷 이미지${1}`}
        />
      )}
      <div className="absolute right-2.5 sm:right-1.5 top-[160px] sm:top-[100px]">
        <Profile
          widthHeight={54}
          time={createdAt}
          nickname={item?.contents[currentContent].uploader.nickname}
          profile={item?.contents[currentContent].uploader.profileImage}
        />
        <div className="flex flex-col items-center space-y-5 mt-[52px] sm:mt-[32px] sm:space-y-3">
          <button
            onClick={handleButton}
            className={`${
              currentSlide?.id !== item?.id ? "cursor-default" : ""
            }`}
          >
            <ImageBtn
              src={Like}
              count={item?.contents[currentContent].likeCount}
            />
          </button>
          <button
            onClick={handleButton}
            className={`${
              currentSlide?.id !== item?.id ? "cursor-default" : ""
            }`}
          >
            <ImageBtn src={DisLike} />
          </button>
          <button
            onClick={handleButton}
            className={`${
              currentSlide?.id !== item?.id ? "cursor-default" : ""
            }`}
          >
            <ImageBtn src={Comment} count={item?.chatCount} />
          </button>
          <button
            onClick={handleButton}
            className={`${
              currentSlide?.id !== item?.id ? "cursor-default" : ""
            }`}
          >
            <ImageBtn src={Start} />
          </button>
          <button
            onClick={handleButton}
            className={`${
              currentSlide?.id !== item?.id ? "cursor-default" : ""
            }`}
          >
            <ImageBtn src={Share} />
          </button>
          <button
            onClick={handleButton}
            className={`${
              currentSlide?.id !== item?.id ? "cursor-default" : ""
            }`}
          >
            <ImageBtn src={More} />
          </button>
        </div>
      </div>
      <div className="absolute bottom-6 left-2.5 sm:left-1.5">
        <Chats chats={item?.chats} width={imageWidth} />
        <div className="mb-4 flex items-center space-x-1.5 sm:space-x-1 mt-6 sm:mt-4 sm:mb-2.5">
          <p
            className={`font-bold text-white py-0.5 px-3 sm:text-[9px] sm:py-0.5 sm:px-2 rounded-full ${
              item?.isLive ? "bg-[#FF2B53]" : "bg-[#777777]"
            }`}
          >
            Live
          </p>
          <div className="flex bg-[rgba(93,93,93,0.3)] py-1 px-2.5 sm:py-0.5 sm:px-2 rounded-full">
            <img src={PersonSearch} className="w-4 h-4 sm:w-3 sm:h-3"></img>
            <span
              className="text-white text-sm sm:text-[9px] sm:leading-[13px]"
              style={{ fontFamily: "Pretendard" }}
            >
              {item?.participantsCount}/{item?.maxPeople}
            </span>
          </div>
        </div>
        <div className="flex space-x-3.5 sm:space-x-2.5 items-center">
          <button
            onClick={handleButton}
            className={`${
              currentSlide?.id !== item?.id ? "cursor-default" : ""
            }`}
          >
            <Profile
              key={item?.owner.id}
              widthHeight={62}
              isFirst
              nickname={item?.owner.nickname}
              profile={item?.owner.profileImage}
            />
          </button>

          {item?.participatings?.map((e, index) => (
            <button
              key={index}
              onClick={handleButton}
              className={`${
                currentSlide?.id !== item?.id ? "cursor-default" : ""
              }`}
            >
              <Profile
                widthHeight={52}
                nickname={e.nickname}
                profile={e.profileImage}
              />
            </button>
          ))}
          {item?.comings?.map((e, index) => (
            <button
              key={index}
              onClick={handleButton}
              className={`${
                currentSlide?.id !== item?.id ? "cursor-default" : ""
              }`}
            >
              <Profile
                key={index}
                widthHeight={52}
                nickname={e.nickname}
                profile={e.profileImage}
                coming
              />
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};
