export const isiOS = () => {
  return /iphone|ipad|Mac/i.test(navigator.userAgent);
};

export const isiPhone = () => {
  return /iphone/i.test(navigator.userAgent);
};

export const isiPad = () => {
  return /ipad/i.test(navigator.userAgent);
};

export const isMacBook = () => {
  return /Mac/i.test(navigator.userAgent);
};

export const isMacOs = () => {
  return isiOS() || isiPad() || isiPhone() || isMacBook();
};
