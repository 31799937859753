import "./index.css";
import { Header } from "./components/Header";
import { useEffect, useState } from "react";
import { PcSlide } from "../RightSectionScreen/components/PcSlide";

export const LiveVideoModal = ({ close, handleModalOn, slideItems }) => {
  const [soundValue, setSoundValue] = useState(0);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.removeProperty("overflow");
    };
  }, []);
  return (
    <div className={"fixed left-0 top-0 z-[999] bg-white"}>
      <div
        className={`relative h-screen w-screen bg-contain bg-no-repeat bg-center bg-white bg-mainBackground`}
      >
        <Header
          close={close}
          setSoundValue={() => {
            setSoundValue((prev) => (prev === 0 ? 1 : 0));
          }}
        />
        <div
          className="absolute w-[729px] h-[729px] opacity-30 rounded-full animation-1 left-[calc(50%-365px)] top-[calc(50%-365px)]"
          style={{
            background:
              "radial-gradient(234.31% 86.52% at 165.65% 50%, #E8BCA3 0%, #6184FF 30.5%, #ED4184 50%, #E8BCA3 54%, #AF53F7 83%)",
            filter: "blur(85px)",
          }}
        ></div>
        <div
          className="absolute w-[729px] h-[729px] opacity-30 rounded-[263px] animation-2 left-[calc(50%-365px)] top-[calc(50%-365px)]"
          style={{
            background:
              "radial-gradient(234.31% 86.52% at 165.65% 50%, #E8BCA3 0%, #6184FF 30.5%, #ED4184 50%, #E8BCA3 54%, #AF53F7 83%)",
            filter: "blur(85px)",
          }}
        ></div>
        <PcSlide soundValue={soundValue} handleModalOn={handleModalOn} slideItems={slideItems} />
      </div>
    </div>
  );
};
