import ArrowSVG from "../../../assets/arrow.svg";

export const Arrow = ({ direction, handleClick }) => {
  return (
    <button
      onClick={handleClick}
      className={`flex justify-center items-center w-10 h-10 rounded-full absolute z-10 top-1/2 ${
        direction === "prev"
          ? "left-[calc(50%-249px)] md:left-[calc(50%-14vw-62px)]"
          : "left-[calc(50%+205px)] md:left-[calc(50%+14vw+22px)]"
      }`}
      style={{
        filter: "drop-shadow(0px 2px 9.4px rgba(103, 103, 103, 0.37))",
        background:
          "linear-gradient(112.83deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0) 110.84%)",
        backdropFilter: "blur(21px)",
      }}
    >
      <img
        src={ArrowSVG}
        className={`${direction === "prev" ? "" : "rotate-180"}`}
      ></img>
    </button>
  );
};
