export const Logo = ({ logo, alt, link }) => {
  return (
    <a
      className="sm:invert sm:opacity-10 hover:cursor-pointer"
      href={link}
      target="_blank"
      rel="noreferrer"
    >
      <img src={logo} alt={alt}></img>
    </a>
  );
};
