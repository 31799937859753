import { useLocation, useNavigate } from "react-router-dom";
import Menu from "../../assets/menu.svg";
import X from "../../assets/X.svg";
import { useEffect, useState } from "react";
export const Header = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [menuOpen, setMenuOpen] = useState(false);
  const [headerFixed, setHeaderFixed] = useState(true);

  useEffect(() => {
    let scrollYtmp = 0;
    window.addEventListener("scroll", (e) => {
      if (window.scrollY - scrollYtmp < 0) {
        setHeaderFixed(true);
      } else {
        setHeaderFixed(false);
      }
      scrollYtmp = window.scrollY;
    });
  }, []);

  return (
    <div
      className="headWrap transition-all"
      style={{ opacity: headerFixed ? 1 : 0 }}
    >
      <div className="inner relative">
        <h1
          className="logo"
          onClick={(e) => {
            e.preventDefault();
            navigate("/");
          }}
        >
          <img src="/assets/ImgLogo.png" alt="엑스페이스 로고" />
        </h1>
        <button
          onClick={() => {
            setMenuOpen((prev) => !prev);
          }}
        >
          <img src={Menu}></img>
        </button>
        <div
          className={`absolute flex pt-[19vh] bg-white items-start justify-end pr-8 w-[255px] h-[100vh] top-0 border-l border-black transition-all rounded-l-[50px] z-10 ${
            menuOpen ? "right-0" : "-right-[255px]"
          }`}
          style={{
            boxShadow: "0px 0px 11.9px rgba(132, 132, 132, 0.25)",
          }}
        >
          <button
            className="absolute top-8 right-8"
            onClick={() => {
              setMenuOpen((prev) => !prev);
            }}
          >
            <img className="w-8 invert-[1]" src={X}></img>
          </button>
          <ul className="nav flex flex-col space-y-16 text-right text-base">
            <li
              className={pathname === "/company" ? "active" : ""}
              onClick={(e) => {
                e.preventDefault();
                navigate("/");
              }}
            >
              소개
            </li>
            <li
              className={pathname === "/notice" ? "active" : ""}
              onClick={(e) => {
                e.preventDefault();
                // navigate("/notice");
              }}
            >
              공지사항
            </li>
            <li
              className={pathname === "/recruit" ? "active" : ""}
              onClick={(e) => {
                e.preventDefault();
                // navigate("/recruit");
              }}
            >
              인재채용
            </li>
            <li
              className={pathname === "/faq" ? "active" : ""}
              onClick={(e) => {
                e.preventDefault();
                // navigate("/faq");
              }}
            >
              FAQ
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
