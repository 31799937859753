export const ImageBtn = ({ src, count = null }) => {
  // <div className="flex">
  // </div>;
  return (
    <div>
      <img src={src} className="w-[28px] h-[28px] sm:w-4 sm:h-4"></img>
      <p
        className="text-xs sm:text-[7px] sm:leading-[7px] text-white"
        style={{ fontFamily: "Pretendard" }}
      >
        {count}
      </p>
    </div>
  );
};
