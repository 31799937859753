import { Chat } from "./Chat";

export const Chats = ({ chats, width }) => {
  if (!chats) return;
  return (
    <div>
      {chats.map(
        (chat, index) =>
          chat && <Chat key={index} chatData={JSON.parse(chat.data)} width={width} />,
      )}
    </div>
  );
};
