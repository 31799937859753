export const Dots = ({ currentSlideIndex, innerInfoDivRef }) => {
  return (
    <>
      <div className="flex space-x-3 sticky bottom-20 pb-20 ml-[285px] md:ml-[135px] lg:ml-[185px] sm:hidden">
        {[0, 1, 2, 3].map((_, index) => (
          <button
            key={index}
            className={`w-2.5 h-2.5 rounded-full sm:w-1.5 sm:h-1.5 ${
              currentSlideIndex === index ? "bg-[#979797]" : "bg-[#D9D9D9]"
            }`}
            onClick={() => {
              if (window.innerWidth > 819) {
                innerInfoDivRef[index].scrollIntoView({
                  behavior: "smooth",
                  block: "center",
                });
              } else {
                innerInfoDivRef[index].scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                });
              }
            }}
          ></button>
        ))}
      </div>
    </>
  );
};
