import QR from "../../assets/XpaceQRCord.png";
import XPaceLogo from "../../assets/XpaceLogo.svg";
import X from "../../assets/X.svg";
import { useEffect } from "react";
export const Modal = ({ close }) => {
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.removeProperty("overflow");
    };
  }, []);
  return (
    <div className="fixed z-[1000] w-[100vw] h-[100vh] top-0 left-0 flex justify-center items-center bg-[rgba(255,255,255,0.5)]">
      <div className="w-[625px] h-[500px] relative">
        <div className="absolute z-10 right-6 top-6">
          <button
            className="p-1.5 rounded-full"
            style={{
              boxShadow: "2px",
            }}
            onClick={close}
          >
            <img src={X}></img>
          </button>
        </div>
        <div
          className="flex flex-col items-center bg-white py-16 px-32 rounded-md"
          style={{
            filter: "drop-shadow(0px 3px 5px rgba(185, 185, 185, 0.5))",
          }}
        >
          <img src={QR} className="w-[155px]"></img>
          <img src={XPaceLogo} className="w-[105px] -mt-6"></img>
          <p className="text-xl font-semibold mb-3">
            지금 바로 앱 다운로드 후 엑스페이스와 함께해요!
          </p>
          <p className="font-[#979797]">
            모임 열기부터 참여까지의 모든 여정을 쉽고 즐겁게!
          </p>
        </div>
      </div>
    </div>
  );
};
