import X from "../../../assets/XBtn.png";
import SoundOnBtn from "../../../assets/soundBtn.png";
export const Header = ({ close, setSoundValue }) => {
  return (
    <div className="bg-transparent">
      <div className="flex justify-between items-center px-8 relative h-[90px]">
        <h1 className="logo">
          <img
            className="w-[133px]"
            src="/assets/ImgLogo.png"
            alt="엑스페이스 로고"
          />
        </h1>
        <div className="absolute top-8 right-8 z-50">
          <button onClick={setSoundValue}>
            <img className="w-[2.5vw]" src={SoundOnBtn}></img>
          </button>
          <button onClick={close}>
            <img className="w-[2.5vw]" src={X}></img>
          </button>
        </div>
      </div>
    </div>
  );
};
