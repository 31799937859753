import "./App.css";
import { Route, Routes } from "react-router-dom";
import Company from "./pages/Company/Company";
import { Footer } from "./components/Footer";
import { Header } from "./components/Header";

function App() {
  return (
    <div className="App sm:overflow-x-hidden">
      <Header />
      <div className="mt-[90px]">
        <Routes>
          <Route path="/" element={<Company />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default App;
