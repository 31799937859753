import { useEffect, useState } from "react";
import { Moment } from "../Moment";
import { MobileSlide } from "./components/MobileSlide";

export const RightSectionScreen = ({
  image,
  imageWidth,
  innerScreenDivRef: { innerScreenDivRef, index },
  onClick = null,
  style = "",
  slideItems = null,
}) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 820);
  useEffect(() => {
    const checkMobileMode = () => {
      if (window.innerWidth < 820) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };
    window.addEventListener("resize", checkMobileMode);
    return () => {
      window.removeEventListener("resize", checkMobileMode);
    };
  }, []);
  return (
    <article
      className={`sticky top-[50%] ${style} -translate-y-1/2 sm:static sm:translate-y-0`}
    >
      <div
        className="screen transition-all duration-500 opacity-0 sm:opacity-100"
        ref={(e) => (innerScreenDivRef.current[index] = e)}
      >
        {index === 0 ? (
          isMobile ? (
            slideItems?.length > 0 && (
              <MobileSlide
                imageWidth={imageWidth}
                slideItems={slideItems}
              />
            )
          ) : (
            <Moment
              imageWidth={imageWidth}
              image={image}
              autoPlay={true}
              loop={true}
            />
          )
        ) : (
          <button
            onClick={!isMobile ? onClick : null}
            className="sm:flex sm:justify-center sm:cursor-default"
          >
            <img
              className={imageWidth}
              src={image}
              alt={`스크린샷 이미지${index + 1}`}
            />
          </button>
        )}
      </div>
    </article>
  );
};
