import "../../App.css";
import GooglePlay from "../../assets/IconGoogleBlack.svg";
import AppStore from "../../assets/IconAppleBlack.svg";
import XpaceLogo from "../../assets/Xpace_final_logo_.png";
import Image02 from "../../assets/Moment.png";
import Image03 from "../../assets/Livechat.png";
import Image04 from "../../assets/Host.png";
import XLogo from "../../assets/XLogo.svg";
import QR from "../../assets/XpaceQRCord.png";
import { useEffect, useRef, useState } from "react";
import { Dots } from "../../components/Dots";
import { LeftSectionInfo } from "../../components/LeftSectionInfo";
import { Modal } from "../../components/Modal";
import { RightSectionScreen } from "../../components/RightSectionScreen";
import { isMacOs } from "../../lib/checkDevices";
import { LiveVideoModal } from "../../components/LiveVideoModal";
import { SB } from "../../lib/sendBird";
import { Moment } from "../../components/Moment";
import { Link, useNavigate } from "react-router-dom";

export default function Company() {
  const [slideItems, setSlideItems] = useState([]);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const innerInfoDivRef = useRef([]);
  const innerScreenDivRef = useRef([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [liveModalOpen, setLiveModalOpen] = useState(window.innerWidth > 819);
  const navigate = useNavigate();
  const buttonGradientStyle = {
    background:
      "linear-gradient(112.48deg, rgba(255, 255, 255, 0.4) 6.31%, rgba(255, 255, 255, 0.9) 98.51%)",
    filter: "drop-shadow(0px 3px 5px rgba(185, 185, 185, 0.5))",
    backdropFilter: "blur(30.5827px)",
  };

  const handleLiveVideoModal = () => {
    setLiveModalOpen(true);
  };

  const handleModalOn = () => {
    setModalOpen(true);
  };

  useEffect(() => {
    SB.connect("889", (user, error) => {
      fetch("https://api.xpace.live/api/v1/cards/main/slides/?pageSize=40")
        .then((res) => res.json())
        .then((res) => {
          console.log(error);
          res.data.map((e, index) => {
            SB.OpenChannel.getChannel(e.chat?.chatUrl, (channel) => {
              channel?.getPreviousMessagesByTimestamp(
                new Date().getTime(),
                false,
                150,
                false,
                "",
                "",
                (messages) => {
                  setSlideItems((prev) =>
                    prev.map((item, index) => {
                      if (item?.id === e.id) {
                        return {
                          ...item,
                          chats: messages?.slice(0, 4),
                          chatCount: messages?.filter((item) => {
                            const data = item?.data?.sender
                              ? item?.data
                              : JSON.parse(item?.data);
                            return !data.isGoing;
                          }).length,
                        };
                      } else {
                        return item;
                      }
                    })
                  );
                }
              );
            });
          });
          setSlideItems(res.data);
        });
    });
  }, []);
  useEffect(() => {
    const innerInfoDivOpacityController = () => {
      if (window.innerWidth < 819) {
        innerInfoDivRef.current.map((e, index) => {
          if (
            index !== 0 &&
            e.getBoundingClientRect().height + 60 > e.getBoundingClientRect().y
          ) {
            setCurrentSlideIndex(index);
          } else if (
            innerInfoDivRef.current[0].getBoundingClientRect().y > -60
          ) {
            setCurrentSlideIndex(0);
          }
          e.style.opacity = 1;
        });
        return;
      }
      innerScreenDivRef.current.map((e, index) => {
        const stickyPosition =
          innerScreenDivRef.current[0].getBoundingClientRect().bottom +
          innerScreenDivRef.current[0].getBoundingClientRect().top;
        if (
          index !== 0 &&
          stickyPosition /
            (e.getBoundingClientRect().bottom + e.getBoundingClientRect().top) >
            0.8
        ) {
          innerScreenDivRef.current[index].style.opacity = 1;
          innerScreenDivRef.current[index - 1].style.opacity = 0;
          setCurrentSlideIndex(index);
        } else if (
          index !== 0 &&
          innerScreenDivRef.current[0].getBoundingClientRect().y > 0
        ) {
          e.style.opacity = 0;
        } else if (innerScreenDivRef.current[0].getBoundingClientRect().y > 0) {
          innerScreenDivRef.current[0].style.opacity = 1;
          setCurrentSlideIndex(0);
        }
      });
      innerInfoDivRef.current.map((e, index) => {
        if (
          -e.getBoundingClientRect().y > e.getBoundingClientRect().height / 3 &&
          -e.getBoundingClientRect().y < e.getBoundingClientRect().height
        ) {
          e.style.opacity = `${
            1 + e.getBoundingClientRect().y / e.getBoundingClientRect().height
          }`;
        } else if (index !== 0) {
          e.style.opacity = `${
            1 - e.getBoundingClientRect().y / e.getBoundingClientRect().height
          }`;
        } else {
          e.style.opacity = 1;
        }
      });
    };
    window.addEventListener("scroll", innerInfoDivOpacityController);
  }, []);

  return (
    <>
      <div className={`bodyWrap`}>
        <div className="containerWrap company">
          <section className="sectionWrap flex px-[135px] sm:items-center sm:flex-col sm:px-0 md:px-10">
            <section className="flex-1 pl-[150px] lg:pl-[50px] md:pl-0 sm:pl-0 sm:flex sm:flex-col sm:items-center">
              <RightSectionScreen
                image={"assets/sample_video1.mp4"}
                style={"hidden sm:block"}
                imageWidth={"w-[242px] h-full"}
                innerScreenDivRef={{ innerScreenDivRef, index: 0 }}
                onClick={handleModalOn}
                slideItems={slideItems}
              />
              <LeftSectionInfo
                subHeaderText={"숏폼 기반 모임 SNS"}
                image={XpaceLogo}
                buttonGradientStyle={buttonGradientStyle}
                discription={
                  "당신을 위한 모임, <br />이제는 숏폼을 확인하고 참여해 보세요."
                }
                innerInfoDivRef={{ innerInfoDivRef, index: 0 }}
                marginTop={"mt-5"}
              />
              <LeftSectionInfo
                headerText={"Moment"}
                subHeaderText={
                  "함께 만들어가는 <br /> 우리들의 모임 스토리, 모멘트"
                }
                discription={
                  "모임의 특별한 순간을 참여자들과 <br />함께 기록하고 공유해보세요."
                }
                innerInfoDivRef={{ innerInfoDivRef, index: 1 }}
                marginTop={"mt-[60px] sm:mt-5"}
                lineHeight={"leading-[70px]"}
              />
              <RightSectionScreen
                image={Image02}
                style={"mt-[10%] hidden sm:block sm:mt-9"}
                imageWidth={"w-[325px]"}
                innerScreenDivRef={{ innerScreenDivRef, index: 1 }}
              />
              <LeftSectionInfo
                headerText={"Live chat"}
                subHeaderText={"실시간으로 연결되는 우리,<br />라이브 채팅"}
                discription={
                  "모임의 모든 순간을 함께 나누고 <br />생생한 대화를 즐겨보세요."
                }
                innerInfoDivRef={{ innerInfoDivRef, index: 2 }}
                marginTop={"mt-[60px] sm:mt-3"}
                lineHeight={"leading-[70px]"}
              />
              <RightSectionScreen
                image={Image03}
                style={"-mt-[10%] hidden sm:block sm:mt-10"}
                imageWidth={"w-[277px]"}
                innerScreenDivRef={{ innerScreenDivRef, index: 2 }}
              />
              <LeftSectionInfo
                headerText={"Host"}
                subHeaderText={
                  "모두가 열광하는 나의 모임,<br />숏폼으로 트렌디하게"
                }
                discription={
                  "생동감 넘치는 숏폼으로 모임을 홍보하여 <br />참여자들의 마음을 사로잡아보세요!"
                }
                innerInfoDivRef={{ innerInfoDivRef, index: 3 }}
                marginTop={"mt-[60px] sm:mt-3"}
                lineHeight={"leading-[70px] "}
              />
              <RightSectionScreen
                image={Image04}
                style={"hidden sm:block sm:mt-12"}
                imageWidth={"w-[334px]"}
                innerScreenDivRef={{ innerScreenDivRef, index: 3 }}
              />
            </section>
            <section className="flex flex-col items-center flex-1 sm:hidden md:items-end">
              <article
                className={`sticky top-[50%] mt-[10%] -translate-y-1/2 sm:static sm:translate-y-0`}
              >
                <div
                  className="screen relative transition-all duration-500 opacity-0 sm:opacity-100"
                  ref={(e) => (innerScreenDivRef.current[0] = e)}
                >
                  <button
                    onClick={handleLiveVideoModal}
                    className=" absolute w-full h-full left-0 z-20"
                  ></button>
                  {!liveModalOpen && (
                    <Moment
                      imageWidth={"w-[375px] h-[716px]"}
                      item={slideItems[0]}
                      currentSlide={slideItems[0]}
                      autoPlay
                    />
                  )}
                </div>
              </article>
              <RightSectionScreen
                image={Image02}
                style={"mt-[10%]"}
                imageWidth={"w-[550px]"}
                innerScreenDivRef={{ innerScreenDivRef, index: 1 }}
                onClick={handleLiveVideoModal}
              />
              <RightSectionScreen
                image={Image03}
                style={"-mt-[10%]"}
                imageWidth={"w-[443px]"}
                innerScreenDivRef={{ innerScreenDivRef, index: 2 }}
                onClick={handleLiveVideoModal}
              />
              <RightSectionScreen
                image={Image04}
                imageWidth={"w-[532px]"}
                innerScreenDivRef={{ innerScreenDivRef, index: 3 }}
                onClick={handleLiveVideoModal}
              />
            </section>
          </section>
          <Dots
            currentSlideIndex={currentSlideIndex}
            innerInfoDivRef={innerInfoDivRef.current}
          />
          <div className="flex py-[300px] border-b justify-center items-center bg-black sm:bg-white text-white sm:text-[#3C3C3C] text-left text-[65px]">
            <img className="mr-7 w-[228px] sm:hidden" src={QR}></img>
            <div>
              <p
                className="relative sm:font-bold sm:text-[40px]"
                style={{
                  fontFamily: "Shadeerah",
                  lineHeight: "110%",
                }}
              >
                What's your
                <img
                  src={XLogo}
                  className="absolute top-0 right-[105px] sm:-right-[50px] sm:top-[0px] sm:w-[50px]"
                ></img>
              </p>

              <p
                className="sm:hidden"
                style={{
                  fontFamily: "Shadeerah",
                  lineHeight: "110%",
                }}
              >
                Scan to download
              </p>
              <div className="flex space-x-4 sm:mt-8 sm:justify-center sm:space-x-0">
                <a
                  href="https://play.google.com/store/apps/details?id=com.xpace.org.rn&pcampaignid=web_share"
                  target="_blank"
                  rel="noreferrer"
                  className={`flex justify-center items-center bg-[#353535] sm:bg-white w-[247px] sm:w-auto h-[78px] sm:h-auto text-2xl sm:text-sm rounded-[20px] sm:rounded-lg sm:py-3 sm:px-4 text-[#FFFFFF] sm:text-[#3C3C3C] ${
                    isMacOs() ? "sm:hidden" : ""
                  }`}
                  style={window.innerWidth < 819 ? buttonGradientStyle : {}}
                >
                  <img
                    className="mr-2 invert sm:w-4 sm:invert-0"
                    src={GooglePlay}
                    alt="플레이스토어 로고"
                  />
                  Google Play
                </a>
                <a
                  href="https://apps.apple.com/kr/app/xpace-%EC%97%91%EC%8A%A4%ED%8E%98%EC%9D%B4%EC%8A%A4-%EB%AA%A8%EC%9E%84-%ED%8C%8C%ED%8B%B0-%EC%86%8C%EC%85%9C-%EC%B7%A8%EB%AF%B8-%ED%96%89%EC%82%AC/id1658395440"
                  target="_blank"
                  rel="noreferrer"
                  className={`flex justify-center items-center bg-[#353535] sm:bg-white w-[247px] sm:w-auto h-[78px] sm:h-auto text-2xl sm:text-sm rounded-[20px] sm:rounded-lg sm:py-3 sm:px-4 text-[#FFFFFF] sm:text-[#3C3C3C] ${
                    isMacOs() ? "" : "sm:hidden"
                  }`}
                  style={window.innerWidth < 819 ? buttonGradientStyle : {}}
                >
                  <img
                    className="mr-2 invert sm:w-4 sm:invert-0"
                    src={AppStore}
                    alt="앱스토어 로고"
                  />
                  App Store
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {liveModalOpen && (
        <LiveVideoModal
          close={() => setLiveModalOpen(false)}
          handleModalOn={handleModalOn}
          modalOpen={modalOpen}
          slideItems={slideItems}
        />
      )}
      {modalOpen && <Modal close={() => setModalOpen(false)} />}
    </>
  );
}
