export const Chat = ({ chatData, width }) => {
  const { nickname, text } = chatData;
  const color = text.includes("참여했습니다.")
    ? "#6BE662"
    : text.includes("가는")
    ? "#F5D94F"
    : "#FFFFFF";

  let match = width.match(/w-\[(\d+)px\]/);
  
  return (
    <div
      className={`flex text-sm sm:text-[9px] sm:leading-3`}
      style={{ color, fontFamily: "Pretendard" }}
    >
      <span
        className={`${
          text.includes("참여") || text.includes("가는")
            ? ""
            : "mr-2 sm:mr-1 font-medium"
        }`}
      >
        {nickname}
      </span>
      <span
        className="overflow-hidden text-ellipsis whitespace-nowrap"
        style={{
          color,
          maxWidth: match[1] * 0.6,
        }}
      >
        {text}
      </span>
    </div>
  );
};
