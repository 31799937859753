import { useEffect, useState } from "react";
import ProfileImgae from "../../../assets/iconProfileDefault@2x.png";
import Badge from "../../../assets/iconHomeBadge@2x.png";

export const Profile = ({
  widthHeight,
  nickname,
  profile,
  isFirst = false,
  time = null,
  coming = false,
}) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 820);
  useEffect(() => {
    const checkMobileMode = () => {
      if (window.innerWidth < 820) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };
    window.addEventListener("resize", checkMobileMode);
    return () => {
      window.removeEventListener("resize", checkMobileMode);
    };
  }, []);
  return (
    <div
      className="profile-image relative"
      style={{
        width: `${isMobile ? widthHeight - 20 : widthHeight}px`,
        height: `${isMobile ? widthHeight - 20 : widthHeight}px`,
      }}
    >
      {isFirst && (
        <img
          src={Badge}
          className="absolute bottom-0 left-0 w-5 h-5 z-10 sm:w-3 sm:h-3"
        ></img>
      )}
      <div className={"relative w-full h-full"}>
        <div
          className={`absolute w-full h-full rounded-full ${
            time ? "bg-white" : coming ? "bg-[#6BE662]" : "bg-[#F5D94F]"
          }`}
        ></div>
        <img
          className={`absolute top-[1px] left-[1px] rounded-full`}
          style={{
            width: `${isMobile ? widthHeight - 22 : widthHeight - 2}px`,
            height: `${isMobile ? widthHeight - 22 : widthHeight - 2}px`,
          }}
          src={profile || ProfileImgae}
          alt={`ProfileImgae`}
        />
      </div>
      <p
        className={`text-[13px] sm:text-[8px] sm:leading-[8px] font-medium text-center sm:mt-0.5 ${
          time ? "text-white" : coming ? "text-[#6BE662]" : "text-[#F5D94F]"
        }`}
        style={{ fontFamily: "Pretendard" }}
      >
        {nickname}
      </p>
      {time && (
        <p
          className="text-white text-xs sm:text-[7px] sm:leading-[7px] text-center"
          style={{ fontFamily: "Pretendard" }}
        >
          {time}
        </p>
      )}
    </div>
  );
};
