import Instagram from "../../assets/Instagram.svg";
import KakaoTalk from "../../assets/kakaotalk.svg";
import TikTok from "../../assets/Tiktok.svg";
import YouTube from "../../assets/YouTube.svg";
import Naver from "../../assets/naverBlog.svg";
import { Logo } from "./Logo";

export const Footer = () => {
  return (
    <div className="flex flex-col items-center py-12 sm:py-4 bg-black sm:bg-white">
      <div className="inner">
        <div className="info">
          <div className="flex gap-2.5 justify-center items-center mb-10 sm:mb-5">
            <Logo
              logo={Instagram}
              alt={"instagram"}
              link={
                "https://www.instagram.com/xpace_kr?igsh=MWxrOHZtZDd0ejVpYQ%3D%3D&utm_source=qr"
              }
            />
            <Logo
              logo={KakaoTalk}
              alt={"kakaotalk"}
              link={"https://pf.kakao.com/_IsxkPG"}
            />
            <Logo
              logo={TikTok}
              alt={"tiktok"}
              link={"https://www.tiktok.com/@xpace_kr"}
            />
            <Logo
              logo={YouTube}
              alt={"youtube"}
              link={"https://www.youtube.com/@xpace_official"}
            />
            <Logo
              logo={Naver}
              alt={"naver"}
              link={"https://blog.naver.com/xpace_korea"}
            />
          </div>
          <div className="text-[#F8F8F8] sm:text-[#DCDCDC] sm:text-[10px]">
            <span className="leading-7 sm:leading-5">
              <span className="font-semibold mr-2">대표</span>
              노이명
            </span>
            <span className="mr-2">
              <span className="font-semibold ml-10 mr-2">
                개인정보관리책임자
              </span>
              노이명
            </span>
            <span>
              <span className="font-semibold mr-2">고객문의</span>
              <a
                className="border-b text-[#F8F8F8] sm:text-[#DCDCDC]"
                href="https://pf.kakao.com/_IsxkPG"
                target="_blank"
                rel="noreferrer"
              >
                @카카오톡채널
              </a>
            </span>
            <br />
            <span className="leading-7 sm:leading-5">
              <span className="font-semibold ml-10 mr-2">사업자번호</span>
              559-86-03065
            </span>
            <span>
              <span className="font-semibold ml-10 mr-2">주소 </span>
              서울 강남구 언주로 103길 10, 4층
            </span>
            <br />
            <span className="leading-7 sm:leading-5">
              <span className="font-semibold mr-2">이메일</span>
              cs@x-pace.co.kr
            </span>
            {/* <span>
              <span className="font-semibold ml-10 mr-2">전화</span>
              010-9607-2944
            </span> */}
            <span>
              <span className="font-semibold ml-10 mr-2">통신판매</span>
              2023-서울강남-06656
            </span>
          </div>
          <ul className="flex justify-center mt-12 text-[#F8F8F8] sm:text-[#DCDCDC] sm:text-[10px] sm:my-6">
            <a
              className="cursor-pointer"
              href="https://xpaceofficial.notion.site/627913ba1d7f4bd4ad9fb1684ae3e736?pvs=4"
              target="_blank"
              rel="noreferrer"
            >
              <li className="border-r pr-8 sm:pr-1.5">이용약관</li>
            </a>
            <a
              className="cursor-pointer"
              href="https://xpaceofficial.notion.site/Xpace-53ab131ec7ac459894cd73e19a48acad?pvs=4"
              target="_blank"
              rel="noreferrer"
            >
              <li className="border-r px-8 sm:px-1.5">개인정보처리방침</li>
            </a>
            <a
              className="cursor-pointer"
              href="https://xpaceofficial.notion.site/Xpace-81fb212e11544517b1cb64ddafc6a23c?pvs=4"
              target="_blank"
              rel="noreferrer"
            >
              <li className="border-r px-8 sm:px-1.5">
                위치기반서비스 이용약관
              </li>
            </a>
            <a className="cursor-pointer">
              <li className="pl-8 sm:pl-1.5">사업자정보확인</li>
            </a>
          </ul>
        </div>
      </div>
    </div>
  );
};
